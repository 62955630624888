<template>
  <div class="search-page">
    <van-nav-bar title="搜索" left-text="返回" left-arrow @click-left="goBack" />
    <div class="search-div">
      <van-search v-model="keyword" show-action placeholder="查线路、站点" @search="onSearch">
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
    </div>
    <div class="divide-div"></div>
    <div v-if="!isSearch" class="search-history">
      <div class="history-title">
        <div class="title-lable">
          <span>搜索历史</span>
        </div>
        <div class="clear-history">
          <van-icon name="delete-o" />
        </div>
      </div>
      <div class="history-search">
        <div v-for="(history, i) in historySearch" :key="i" class="history-list">
          <!-- 历史查询中的查询站点 -->
          <div
            v-if="history.type == 'station'"
            class="history-item verticle-center"
            @click="queryByStationName(history.name)"
          >
            <van-icon name="filter-o" />
            <span>{{ history.name }}</span>
          </div>
          <!-- 历史查询中的查询线路 -->
          <div
            v-else-if="history.type == 'line'"
            class="history-item verticle-center"
            @click="queryByLine(history.lineName, history.upperOrDown, history.to)"
          >
            <van-icon name="font-o" />
            <span>{{ history.lineName }} &#32;开往&#32;{{ history.to }}</span>
          </div>
          <van-divider />
        </div>
      </div>
    </div>
    <div v-if="isSearch" class="search-result">
      <div v-for="(history, i) in searchResult" :key="i" class="result-list">
        <!-- 历史查询中的查询站点 -->
        <div
          v-if="history.type == 'station'"
          class="result-item verticle-center"
          @click="queryByStationName(history.name)"
        >
          <van-icon name="filter-o" />
          <span>{{ history.name }}</span>
        </div>
        <!-- 历史查询中的查询线路 -->
        <div
          v-else-if="history.type == 'line'"
          class="result-item verticle-center"
          @click="queryByLine(history.lineName, history.upperOrDown)"
        >
          <van-icon name="font-o" />
          <span>{{ history.lineName }} &#32;开往&#32;{{ history.to }}</span>
        </div>
        <van-divider />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { search } from '@/api/mygolbs'
export default {
  name: 'Search',
  data() {
    return {
      keyword: '',
      isSearch: false,
      historySearch: [
        { type: 'station', name: '福州市政府站' },
        { type: 'line', lineName: '106路', to: '火车站北广场站', upperOrDown: 1 },
      ],
      searchResult: [
        { type: 'station', name: '福州市政府站' },
        { type: 'line', lineName: '106路', to: '火车站北广场站', upperOrDown: 1 },
      ],
    }
  },
  computed: {
    ...mapGetters(['location']),
  },
  mounted() {
    this.onFocus()
  },
  activated() {
    this.onFocus()
  },
  methods: {
    onFocus() {
      document.getElementsByClassName('van-field__control')[0].focus()
    },
    goBack() {
      this.$router.go(-1)
    },
    onSearch() {
      search(this.keyword, this.location.city).then(res => {
        let result = []
        let buslines = res.buslines
        if (buslines.length > 0) {
          for (let line of buslines) {
            let a = {}
            a.type = 'line'
            a.lineName = line.lineName
            a.to = line.to
            a.upperOrDown = line.upperOrDown
            result.push(a)
          }
        }
        let busstations = res.busstations
        if (busstations.length > 0) {
          for (let station of busstations) {
            let a = {}
            a.type = 'station'
            a.name = station.stationName
            result.push(a)
          }
        }
        this.searchResult = result
      })

      this.isSearch = true
    },
    //按站点查询
    queryByStationName(stationName) {
      this.$router.push({ name: 'BusStation', params: { stationName: stationName } })
    },
    //按线路查询
    queryByLine(lineName, upperOrDown, to) {
      this.$router.push({ name: 'BusLine', params: { lineName: lineName, upperOrDown: upperOrDown, to: to } })
    },
  },
}
</script>
<style lang="scss" scoped>
.search-history {
  .history-title {
    padding-left: 20px;
    padding-right: 20px;
    height: 30px;
    .title-lable {
      float: left;
      width: 100px;
    }
    .clear-history {
      float: right;
      padding: 5px;
    }
  }
  .history-search {
    padding: 0 20px 20px 20px;
    background-color: #fff;
    border-radius: 8px;
    .history-list {
      height: 40px;
      .history-item {
        span {
          padding-left: 20px;
        }
      }
    }
  }
}

.search-result {
  padding: 0 20px 20px 20px;
  background-color: #fff;
  border-radius: 8px;
  .result-list {
    height: 40px;
    .result-item {
      span {
        padding-left: 20px;
      }
    }
  }
}
.divide-div {
  height: 50px;
}
.verticle-center {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
</style>
